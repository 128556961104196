<template>
	<div class="max-box">
		<div class="header-box">
			<div class="header-left">
				<span>设备</span>
			</div>
			<div></div>
		</div>
		<div class="content-box">
			<div class="tarbar-box" style="position: relative">
				<div class="tabs">
					<div
						class="tab-one"
						v-for="tab in tabList"
						:class="activeTab == tab.value ? 'active-tab' : ''"
						@click="changeTab(tab.value)"
					>
						<div>{{ tab.label }}</div>
					</div>
				</div>
				<div class="right-box">
					<span v-if="activeTab==3"><span style="font-weight: 700">发电量</span>：{{ totalPower }}</span><span
					style="margin: 0 10px" v-if="activeTab==3"><span
					style="font-weight: 700">功率归一化</span>：{{ powerRate }}%</span>
					<a @click="getListByParam(null)" style="margin-right: 10px"
					   v-if="activeTab==1 || activeTab==3">清除查询</a>
					<a-icon
						type="vertical-align-bottom"
						title="导出"
						@click="exportData"
						v-if="activeTab != 6 "
					/>
					<a-icon type="sync" class="icon-left" @click="reload" title="刷新"/>
				</div>
			</div>
			<div class="list-box" ref="yHeight">
				<div class="weather-box" v-if="activeTab == 6">
					<div class="one-msg" v-for="item in weatherData">
						<span>{{ item.title }}：</span>
						<span class="one-detail"
						>{{ item.value }} {{ item.value == "--" ? "" : item.unit }}</span
						>
					</div>
				</div>
				<myTable
					:table-loading="tableLoading"
					:columns="columns"
					:dataSource="tableData"
					:total="total"
					@changePagination="getTableList"
					:scroll="{ x: getXWidth, y: yHeight }"
					v-else>
					<template #dispersion>
						<div>装机容量(KWP)</div>
					</template>
					<!--根据安装位置进行查询-->
					<template slot="childSystem" slot-scope="slotData">
						<a @click="getListByParam(slotData.scope.childSystem)">{{ slotData.scope.childSystem }}</a>
					</template>
					<template slot="deviceName" slot-scope="slotData">
						<div @click="toDetail(slotData.scope)" class="name-box">
							<div>
								{{ slotData.scope.deviceTitle ?? "--" }}
							</div>
							<div v-if="activeTab == 5">
								METER{{ slotData.scope.deviceSn }}
							</div>
							<div v-else>
								{{
									activeTab == 3
										? slotData.scope.inverterId
										: slotData.scope.deviceSn
								}}
							</div>
						</div>
					</template>
					<template #inverStatus="slotData">
						<a-tag :color="getInverStatus(slotData.scope.workStatus).color">
							{{ getInverStatus ( slotData.scope.workStatus ).label }}
						</a-tag>
					</template>
					<template #monitoringStatus="slotData">
						{{ getMonitoringStatus ( slotData.scope.workStatus ) }}
					</template>

					<template slot="operation" slot-scope="slotData">
						<div class="operation-box">
							<a-icon
								type="edit"
								class="icon-left"
								title="编辑"
								@click="editOpen(slotData.scope)"
							/>
						</div>
					</template>
				</myTable>
			</div>
		</div>

		<!-- 详情抽屉 -->
		<a-drawer
			placement="right"
			:closable="false"
			:visible="detailDrawer"
			width="83%"
			wrapClassName="add-power"
			@close="closeDetailDrawer"
			:destroyOnClose="true"
			:headerStyle="{
                    background: '#fff',
                    padding: '0.2rem 0.25rem 0 0.25rem',
                    flexGrow: 0,
                  }"
			:bodyStyle="{
                    padding: '0.3rem 0 0.2rem 0',
                    background: 'fff',
                    flexGrow: 1,
                    overflowY: 'auto',
                  }"
			:drawerStyle="{
                    background: '#EEEEEF',
                    display: 'flex',
                    flexDirection: 'column',
                  }"
		>
			<template #title>
				<div class="head-box">
					<div class="name-box">
						<span>{{ getTabName }}</span>
						<div class="operation-right">
							<a-icon
								type="sync"
								title="刷新"
								class="mr_16"
								@click="getDetail"/>
							<div class="close-box" @click="closeDetailDrawer">
								<a-icon type="close" class="close-icon"/>
							</div>
						</div>
					</div>
					<div class="status-box">
						<div class="left-box">
							<a-icon
								type="check-circle"
								theme="twoTone"
								:two-tone-color="getInverStatus(currentData.workStatus).color"
							/>
							<span>{{ getInverStatus ( currentData.workStatus ).label }}</span>
						</div>
						<span class="right-time">{{ moment ().format ( "YYYY-MM-DD HH:mm:ss" ) }}</span>
					</div>
				</div>
				<div class="drawer-tab-box">
					<div class="all-tabs">
                        <span
	                        class="drawer-tab"
	                        @click="changeDrawerTab(drawertab.value)"
	                        v-for="drawertab in drawerTabList"
	                        :class="drawertab.value == drawerActive ? 'active-drawer-tab' : ''">{{ drawertab.label }}
                        </span>
					</div>
				</div>
			</template>
			<div class="content-box">
				<!--逆变器详情-->
				<inverter-detail :sn="currentData.deviceSn" :id="currentData.id" v-if="activeTab==2"></inverter-detail>
				<inverter-history-data :sn="currentData.deviceSn" v-if="drawerActive==3"></inverter-history-data>
			</div>
		</a-drawer>
		<editDrawer
			@closeEditDrawer="closeEditDrawer"
			:currentData="currentData"
			@reloadTable="reloadTable"
			v-if="topDrawer"
			:topDrawer="topDrawer"
		></editDrawer>
		<a-modal
			title="编辑"
			:visible="deviceVisible"
			@ok="deviceEditOk"
			@cancel="deviceEditCancel"
		>
			<a-form-model ref="editForm" :model="editForm" :rules="editRules">
				<a-form-model-item label="设备名称" prop="deviceTitle">
					<a-input v-model="editForm.deviceTitle"/>
				</a-form-model-item>
				<a-form-model-item label="安装位置" prop="address">
					<a-input v-model="editForm.address"/>
				</a-form-model-item>
				<a-form-model-item label="装机容量" prop="power">
					<a-input v-model="editForm.power"/>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>
<script>
import {
	queryDeviceList,
	queryInverterDetails,
	queryMonitorDetails,
	deviceUpdate,
	robotStart,
	autoControl,
	downloadDevice, getInverterList
} from "@/api/device";
import allColumns from "./config/deviceColumns";
import moment from "moment";
import collectDetail from "@/views/monitoring/device-components/collectDetail";
import monitoringDetail from "./device-components/monitoringDetail.vue";
import inverseDetail from "./device-components/inverseDetail.vue";
import optimizeDetail from "./device-components/optimizeDetail.vue";
import ammeterDetail from "./device-components/ammeterDetail.vue";
import alarmMsg from "./device-components/alarmMsg.vue";
import historyData from "./device-components/historyData.vue";
import survey from "./monitoring-components/survey.vue";
import joinMsg from "./device-components/joinMsg.vue";
import powerThermal from "@/views/monitoring/monitoring-components/powerThermal";
import temperature from "@/views/monitoring/monitoring-components/temperature";
import statusCode from "./config/statusCode";
import editDrawer from "./components/editDrawer.vue";
import robotDetail from "./device-components/robotDetail.vue";
import robotSetting from "./components/robotSetting.vue";
import ammeterHistoryVue from "./device-components/ammeterHistory.vue";
import {exportDown, debound} from "@/utils/index.js";
import {queryDeviceCount} from "../../api/device";
import InverterDetail from "@/views/monitoring/components/inverterDetail";
import InverterHistoryData from "@/views/monitoring/components/inverterHistoryData";

export default {
	data() {
		const inverseStatus = [
			{value: 0, label: "正常", color: "blue"},
			{value: 8000, label: "停机", color: "orange"},
			{value: 1300, label: "按键关机", color: "orange"},
			{value: 1500, label: "紧急停机", color: "orange"},
			{value: 1400, label: "待机", color: "orange"},
			{value: 1200, label: "初始待机", color: "orange"},
			{value: 1600, label: "启动中", color: "orange"},
			{value: 9100, label: "告警运行", color: "orange"},
			{value: 8100, label: "降额运行", color: "orange"},
			{value: 8200, label: "调度运行", color: "orange"},
			{value: 5500, label: "故障停机", color: "orange"},
			{value: 2500, label: "通讯故障", color: "orange"},
			{value: 9999, label: "离线", color: "red"},
		];
		return {
			activeTab: 1,
			inverseStatus,
			testData: "",
			tabList: [
				{
					value: "1",
					label: "采集器",
				},
				{
					value: "2",
					label: "逆变器",
				},
			],
			columns: [],
			tableData: [],
			total: 0,
			totalPower: 0, // 所选逆变器总的发电量
			powerRate: 0,   // 发电效率，所选逆变器总发电量/所选逆变器总装机容量
			tableLoading: true, // 表格加载loading
			detailDrawer: false,
			getTabName: "",
			currentData: {},
			moment,
			//抽屉的tab
			drawerTabList: [
				{
					value: "0",
					label: "连接信息",
				},
			],
			// 常用tab
			normalTabList: [
				{
					value: "0",
					label: "详情",
				},
				{
					value: "1",
					label: "报警信息",
				},
				// {
				// 	value: "2",
				// 	label: "连接信息",
				// },
				// {
				// 	value: "3",
				// 	label: "历史数据",
				// },
			],
			// 抽屉的选中tab
			drawerActive: 0,
			// 动态组件
			activeComponent: [
				[
					"collectDetail", //逆变器
					"inverseDetail", //采集器
				],
				"alarmMsg",
				"joinMsg",
				"historyData",
			],
			// 详情数据
			detailList: {},
			code: "",
			isReload: false,
			// 设备请求详情接口
			requestApi: [queryInverterDetails, queryMonitorDetails],
			// 编辑抽屉
			topDrawer: false,

			// 编辑名称
			deviceVisible: false,
			editForm: {
				deviceTitle: "",
				childSystem: ""
			},
			editRules: {
				deviceTitle: [
					{required: true, message: "请填写设备名称", trigger: "blur"},
				],
			},
			// 机器人设置弹框
			robotVisible: false,
			yHeight:
				document.documentElement.clientHeight -
				document.documentElement.clientHeight / 3.5,
			childSystem: '', // 点击设备安装位置查询参数
		};
	},
	components: {
		InverterHistoryData,
		InverterDetail,
		collectDetail,
		monitoringDetail,
		inverseDetail,
		optimizeDetail,
		alarmMsg,
		historyData,
		ammeterDetail,
		survey,
		powerThermal,
		editDrawer,
		temperature,
		joinMsg,
		robotDetail,
		robotSetting,
		ammeterHistoryVue,
	},
	methods: {
		getMonitoringStatus( status ) {
			let statusValue = this.monitoringStatus.find ( ( item ) => {
				return item.value == status;
			} );
			if ( statusValue ) {
				return statusValue.label;
			} else {
				return "未知状态";
			}
		},
		getInverStatus( status ) {
			let statusValue = this.inverseStatus.find ( ( item ) => {
				return item.value == status;
			} );
			if ( statusValue ) {
				return statusValue;
			} else {
				return "未知状态";
			}
		},
		changeTab( value ) {
			this.activeTab = value;
			this.drawerActive = 0;
			this.columns = allColumns[this.activeTab]; // 表格表头配置项
			if ( value == 1 ) {
				this.drawerTabList = [
					{
						value: "0",
						label: "连接信息",
					},
				];
			} else if ( value == 2 ) {
				this.drawerTabList = this.normalTabList;
			}
			this.getTableList ();
		},
		// 点击设备安装位置，条件查询
		getListByParam( childSystem ) {
			this.childSystem = childSystem
			this.getTableList ()
		},
		// 获取表格数据
		getTableList( pageMsg ) {
			let requestPage = {
				page: 1,
				limit: 50,
			};
			if ( pageMsg?.page ) {
				requestPage.page = pageMsg.page;
				requestPage.limit = pageMsg.pageSize;
			}
			let device = {deviceType: this.activeTab, ...requestPage};
			this.tableLoading = true
			queryDeviceList ( device ).then ( ( res ) => {
				res.data?.map ( ( item ) => {
					let statusMsg = statusCode.find ( ( s ) => {
						return s.status == item.parentStatus;
					} );
					if ( statusMsg ) {
						item.parentStatus = statusMsg.label;
					} else {
						item.parentStatus = "--";
					}
					item.autoStatus = undefined;
				} );
				this.tableData = res.data;
				this.tableLoading = false
				queryDeviceCount ( device ).then ( result => {
					this.total = result.data;
				} )
			} ).catch ( () => {
				console.log ( "查询设备异常" );
			} );
		},
		// 打开抽屉详情
		toDetail( data ) {
            if (this.activeTab == 2) {
                this.getTabName = `${data.deviceTitle ?? "--"}：${data.deviceSn}`;
                this.currentData = data;
                this.detailDrawer = true;
            }
		},
		closeDetailDrawer() {
			this.detailDrawer = false;
		},

		// 更改抽屉里的tab
		changeDrawerTab( value ) {
			console.log ( value )
			this.drawerActive = value;
		},

		// 获取详情信息
		getDetail() {
			console.log ( this.activeTab )
			let request = this.requestApi[this.activeTab - 1];
			if ( request ) {
				request ( {id: this.currentData.id} )
					.then ( ( res ) => {
						this.detailList = res.data;
						this.code = res.data?.inverterSystem?.workStatus;
					} )
					.catch ( ( err ) => {
						this.$message.error ( {content: err} );
					} );
			}
		},

		// 编辑操作
		editOpen( data ) {
            console.log(data)
			this.currentData = data;
			if ( this.activeTab == 3 ) {
				this.topDrawer = true;
			} else {
				this.deviceVisible = true;
				this.editForm.deviceTitle = data.deviceTitle;
				this.editForm.address = data.address;
				this.editForm.power = data.power;
			}
		},
		deviceEditCancel() {
			this.deviceVisible = false;
		},
		deviceEditOk() {
			this.$refs.editForm.validate ( ( valid ) => {
				if ( valid ) {
					deviceUpdate ( {
						deviceTitle: this.editForm.deviceTitle,
						address: this.editForm.address,
                        power: this.editForm.power,
						id: this.currentData.id,
					} ).then ( ( res ) => {
						if ( res.code == "000000" ) {
							this.$message.success ( "修改设备名称成功" );
							this.getTableList ();
							this.deviceVisible = false;
						}
					} );
				} else {
					return false;
				}
			} );
		},
		// 关闭编辑框
		closeEditDrawer() {
			this.topDrawer = false;
		},
		reloadTable() {
			this.topDrawer = false;
			this.getTableList ();
		},
		toSystem( record ) {
			const routeUrl = this.$router.resolve ( {
				path: "/station/main",
				query: {
					id: record.stationId,
				},
			} );
			window.open ( routeUrl.href, "_blank" );
		},

		sendApi() {
			robotStart ( {data: this.testData} ).then ( ( res ) => {
				console.log ( res );
			} );
		},
		changeAuto( e, item ) {
			autoControl ( {id: item.id, autoControl: e.target.value} ).then ( ( res ) => {
				if ( res.code == "000000" ) {
					this.getTableList ();
				}
			} );
		},
		reload() {
			if ( this.activeTab == 6 ) {
				this.getWeatherStation ();
			} else {
				this.getTableList ();
			}
		},
		exportData() {
			downloadDevice ( {deviceType: this.activeTab} ).then ( ( res ) => {
				exportDown ( res, "设备列表" );
			} );
		},
		watchResize() {
			this.yHeight = this.$refs["yHeight"].clientHeight - 100;
			window.addEventListener ( "resize", this.screenResize );
		},
		screenResize: debound ( function () {
			// document.documentElement.clientHeight;
			this.yHeight = this.$refs["yHeight"].clientHeight - 100;
			console.log ( this.$refs["yHeight"].getBoundingClientRect (), "---------" );
			// this.yHeight =
			//   document.documentElement.clientHeight -
			//   document.documentElement.clientHeight / 3.5;
		}, 200 ),
	},
	mounted() {
		this.columns = allColumns[this.activeTab];
		this.getTableList ();
		this.watchResize ();
	},
	beforeDestroy() {
		window.removeEventListener ( "resize", this.screenResize );
	},
	computed: {
		getXWidth() {
			return this.columns.length * 135;
		},
		// 计算组件
		getComponent() {
			console.log ( this.activeTab, this.drawerActive )
			if ( this.activeTab == 2 ) {
				//this.currentData.strandNo = this.drawerTabList[this.drawerActive].strandNo
				//this.currentData.optimizerSystemId = this.drawerTabList[this.drawerActive].optimizerSystemId
				return this.activeComponent[0][this.activeTab - 1];
			}
			// if ( this.activeTab == 1 ) {
			// 	return "joinMsg";
			// }
			// if ( this.activeTab == 2 && this.drawerActive != 0 ) {
			// 	return this.monitoringComponent[this.drawerActive];
			// }
			// if ( this.drawerActive == 0 ) {
			// 	return this.activeComponent[0][this.activeTab - 1];
			// }
			// if ( this.activeTab == 5 && this.drawerActive == 3 ) {
			// 	return "ammeterHistoryVue";
			// }
			return this.activeComponent[this.drawerActive];
		},

		// 获取状态
		getStatus() {
			let statusMsg = statusCode.find ( ( s ) => {
				return s.status == this.code;
			} );
			if ( statusMsg ) {
				return statusMsg.label;
			} else {
				return "---";
			}
		},
	},
};
</script>
<style lang="less" scoped>
.max-box {
	width: 100%;
	padding-top: 16px;
	height: 100%;
	padding-right: 20px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;

	.header-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		// padding-right: 20px;
		margin-bottom: 16px;

		.header-left {
			span {
				font-size: 18px;
				color: #252b3a;
			}

			i {
				font-size: 20px;
			}
		}

		.search-box {
			width: 240px;
			height: 32px;

			/deep/ .ant-input {
				font-size: 12px;
			}
		}

		.add-btn {
			font-size: 12px;
		}
	}

	.content-box {
		background: #fff;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		// tabs
		.tarbar-box {
			padding-bottom: 12px;
			position: relative;
			padding-right: 16px;
			width: 100%;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			display: flex;
			justify-content: space-between;
			align-items: center;

			.tabs {
				display: flex;
				padding-left: 16px;
				align-items: center;
				box-sizing: border-box;

				.tab-one {
					padding: 10px 0 8px 0;
					margin-right: 24px;
					font-size: 12px;
					cursor: pointer;
					color: #515561;
					box-sizing: border-box;
				}

				.open-choose {
					margin-top: 3px;
					cursor: pointer;
					padding-left: 16px;
					border-left: 1px solid rgba(0, 0, 0, 0.1);

					&:hover {
						color: #048fff;
					}

					span {
						margin-right: 1px;
					}

					transition: all 1s;
				}

				.active-choose {
					color: #048fff;

					.icon-down {
						transform: rotate(180deg);
					}
				}

				.active-tab {
					color: #048fff;
					border-bottom: 2px solid #048fff;
				}
			}

			.right-box {
				font-size: 16px;

				i {
					cursor: pointer;

					&:hover {
						color: #048fff;
					}
				}

				.icon-left {
					margin-left: 20px;
					transition: all 0.4s;

					&:hover {
						transform: rotate(90deg);
					}
				}
			}
		}

		.list-box {
			flex-grow: 1;

			.weather-box {
				height: 100%;
				overflow-y: auto;
				display: grid;
				padding: 16px;
				box-sizing: border-box;
				grid-template-columns: repeat(3, 1fr); /* 三列等宽 */
				grid-auto-rows: 60px; /* 自动调整行高度 */
				grid-gap: 10px; /* 列间距和行间距，可根据需要调整 */

				.one-msg {
					font-size: 12px;
					color: #00000073;

					.one-detail {
						color: #515561;
					}
				}
			}
		}

		.name-box {
			cursor: pointer;
			width: 100%;
		}

		// 操作样式
		.operation-box {
			width: 100%;
			padding-left: 15px;
			font-size: 16px;

			i {
				cursor: pointer;
				transition: all 0.5s;

				&:hover {
					color: #048fff;
				}
			}

			.icon-left {
				margin-left: 30px;
			}
		}
	}
}

// 抽屉内容
.add-power {
	transform: translate(0) !important;

	.head-box {
		.name-box {
			display: flex;
			justify-content: space-between;

			span {
				font-size: 18px;
				color: #252b3a;
			}

			.operation-right {
				display: flex;
				align-items: center;
				font-size: 16px;
				color: rgba(0, 0, 0, 0.45);

				.mr_16 {
					margin-right: 16px;
					transition: all 0.5s;
					cursor: pointer;

					&:hover {
						color: #048fff;
					}
				}

				.close-box {
					background: #ff7875;
					cursor: pointer;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 28px;
					height: 28px;
					font-size: 16px;
					color: #fff;
					border-radius: 50%;

					&:hover {
						i {
							transform: rotate(90deg);
						}
					}

					i {
						transition: all 0.3s;
					}
				}
			}
		}

		.status-box {
			display: flex;
			justify-content: space-between;
			margin-top: 10px;

			.left-box {
				display: flex;
				align-items: center;

				i {
					font-size: 12px;
				}

				span {
					font-size: 14px;
					color: #515561;
					margin-left: 6px;
				}
			}

			.right-time {
				font-size: 12px;
				color: #515561;
			}
		}
	}

	.drawer-tab-box {
		padding: 0 8px;
		margin-top: 16px;

		.all-tabs {
			display: flex;

			span {
				padding: 12px 0 8px;
				margin-right: 24px;
				font-size: 12px;
				color: #00000073;
				cursor: pointer;
			}

			.active-drawer-tab {
				color: #048fff;
				border-bottom: 2px solid #048fff;
				font-weight: 700;
			}
		}
	}

	.content-box {
		padding: 0 16px;
		box-sizing: border-box;
	}
}
</style>
