<template>
  <a-modal
    title="设置全战策略"
    :visible="robotVisible"
    @ok="handleOk"
    width="50%"
    @cancel="handleCancel"
  >
    <myTable
      :columns="columns"
      :dataSource="tableData"
      :pagination="false"
      :myPageShow="false"
    >
      <template #setting="slotData">
        <!-- <a-select
          v-model="slotData.scope.intervalDay"
          v-if="slotData.scope.strategyNumber == 0"
          class="select-box"
        >
          <template slot="placeholder">请选择控制状态</template>
          <a-select-option :key="item.value" v-for="item in autoList">
            {{ item.label }}
          </a-select-option>
        </a-select> -->
        <template>
          <div class="setting-box">
            <a-date-picker
              format="MM-DD HH:mm"
              v-model="slotData.scope.startTime"
              placeholder="输入执行日期"
              class="date-box"
              :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
            />
            <a-input
              v-model="slotData.scope.intervalDay"
              class="input-box"
              placeholder="执行间隔/天"
            ></a-input>
          </div>
        </template>
      </template>
    </myTable>
  </a-modal>
</template>
<script>
import { robotStrategy, robotStrategySet } from "@/api/device.js";
import moment from "moment";
export default {
  data() {
    return {
      moment,
      columns: [
        { dataIndex: "index", title: "序号", width: "70" },
        { title: "参数名称", dataIndex: "paramTitle", width: "200" },
        { title: "单位", dataIndex: "unit", width: "100" },
        {
          title: "设置值",
          scopedSlots: {
            customRender: "setting",
          },
          //   width: "140",
        },
      ],
      tableData: [
        {
          index: "01",
          paramTitle: "策略1",
          unit: "--",
          startTime: "",
          intervalDay: "",
          strategyNumber: 1,
        },
        {
          index: "02",
          paramTitle: "策略2",
          unit: "--",
          startTime: "",
          intervalDay: "",
          strategyNumber: 2,
        },
        {
          index: "03",
          paramTitle: "策略3",
          unit: "--",
          startTime: "",
          intervalDay: "",
          strategyNumber: 3,
        },
        {
          index: "04",
          paramTitle: "策略4",
          unit: "--",
          startTime: "",
          intervalDay: "",
          strategyNumber: 4,
        },
        // {
        //   index: "05",
        //   paramTitle: "自动控制状态",
        //   unit: "--",
        //   intervalDay: undefined,
        //   strategyNumber: 0,
        // },
      ],
      autoList: [
        {
          value: 0,
          label: "不允许自动控制",
        },
        {
          value: 1,
          label: "允许自动控制",
        },
        {
          value: 2,
          label: "禁止当天定时启动",
        },
      ],
      copyData: [
        {
          index: "01",
          paramTitle: "策略1",
          unit: "--",
          startTime: "",
          intervalDay: "",
          strategyNumber: 1,
        },
        {
          index: "02",
          paramTitle: "策略2",
          unit: "--",
          startTime: "",
          intervalDay: "",
          strategyNumber: 2,
        },
        {
          index: "03",
          paramTitle: "策略3",
          unit: "--",
          startTime: "",
          intervalDay: "",
          strategyNumber: 3,
        },
        {
          index: "04",
          paramTitle: "策略4",
          unit: "--",
          startTime: "",
          intervalDay: "",
          strategyNumber: 4,
        },
        // {
        //   index: "05",
        //   paramTitle: "自动控制状态",
        //   unit: "--",
        //   startTime: "",
        //   intervalDay: undefined,
        //   strategyNumber: 0,
        // },
      ],
    };
  },
  props: {
    robotVisible: {
      type: Boolean,
      default: false,
    },
    currentData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    robotVisible(newv) {
      if (newv) {
        this.getRobotSet();
      }
    },
  },
  methods: {
    getRobotSet() {
      this.tableData = JSON.parse(JSON.stringify(this.copyData));
      robotStrategy({ robotId: this.currentData.id }).then((res) => {
        this.tableData.forEach((item) => {
          res.data.forEach((item2) => {
            if (item.strategyNumber == item2.strategyNumber) {
              item.intervalDay = item2.intervalDay;
              item.startTime = item2.startTime ? moment(item2.startTime) : "";
            }
          });
        });
      });
    },
    handleCancel() {
      this.$emit("update:robotVisible", false);
    },
    handleOk() {
      let dealData = this.tableData
        .map((item) => {
          let paramData = { ...item };
          delete paramData.index;
          delete paramData.unit;
          delete paramData.paramTitle;
          return {
            ...paramData,
            startTime: item.startTime
              ? moment(item.startTime).format("YYYY-MM-DD HH:mm:ss")
              : "",
          };
        })
        .filter((item) => {
          if (item.strategyNumber == 0) {
            return item.intervalDay;
          } else {
            return item.intervalDay && item.startTime;
          }
        });
      robotStrategySet({
        robotStrategyEntities: dealData,
        robotId: this.currentData.id,
      }).then((res) => {
        if (res.code == "000000") {
          this.$emit("update:robotVisible", false);
          this.$message.success("修改成功");
        }
        console.log(res);
      });
      //   console.log(this.tableData, "---");
    },
  },
};
</script>
<style lang="less" scoped>
.setting-box {
  display: flex;
  justify-content: space-between;
  width: 400px;
  .date-box {
    height: 32px;
    width: 200px;
    /deep/input {
      height: 32px;
      font-size: 12px;
      //   width: 160px;
    }
  }
  .input-box {
    height: 32px;
    font-size: 12px;
    width: 140px;
  }
}
/deep/.select-box {
  width: 300px;
  height: 32px;
  .ant-select-selection--single {
    height: 32px;

    font-size: 12px;
    .ant-select-selection__rendered {
      line-height: 32px;
    }
  }
}
</style>