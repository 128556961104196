<template>
	<!--逆变器历史数据 TTL -->
	<div style="background: #fff;padding-bottom: 20px">
		<a-table
			:columns="columns"
			:loading="tableLoading"
			:dataSource="inverterEntities"
			size="small">
		</a-table>
	</div>
</template>

<script>
import {queryInverterDetailList} from "@/api/device";

export default {
	name: "inverterHistoryData",
	props: {
		sn: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			columns: [
				{
					title: '序号',
					dataIndex: 'index',
					customRender: (text, record, index) => {
						return index + 1;
					},
					fixed: "left",
				},
				{
					title: '温度',
					dataIndex: 'inverTemp',
					key: 'inverTemp',
				},
				{
					title: 'PV1电压',
					dataIndex: 'pv1Voltage',
					key: 'pv1Voltage',
				},
				{
					title: 'PV1电流',
					dataIndex: 'pv1Current',
					key: 'pv1Current',
				},
				{
					title: 'PV2电压',
					dataIndex: 'pv2Voltage',
					key: 'pv2Voltage',
				},
				{
					title: 'PV2电流',
					dataIndex: 'pv2Current',
					key: 'pv2Current',
				},
				{
					title: '电网电流',
					dataIndex: 'gridCurrent',
					key: 'gridCurrent',
				},
				{
					title: '电网电压',
					dataIndex: 'gridVoltage',
					key: 'gridVoltage',
				},
				{
					title: '电网频率',
					dataIndex: 'gridFrequency',
					key: 'gridFrequency',
				},
				{
					title: '电网功率',
					dataIndex: 'gridPower',
					key: 'gridPower',
				},
				{
					title: '今天发电量',
					dataIndex: 'todayEnergy',
					key: 'todayEnergy',
				},
				{
					title: 'PV1当日发电量',
					dataIndex: 'channelAEnergy',
					key: 'channelAEnergy',
				},
			],
			tableLoading: true,
			inverterEntities: []
		}
	},
	created() {
		this.queryList()
	},
	methods: {
		queryList() {
			queryInverterDetailList ( {serialNumber: this.sn, createTime: '2024-06-09'} ).then ( res => {
				console.log ( res.data )
				this.inverterEntities = res.data
				this.tableLoading = false
			} )
		}
	}
}
</script>

<style scoped>

</style>
