<template>
  <!-- 详情抽屉 -->
  <a-drawer
    placement="right"
    :closable="false"
    :visible="detailDrawer"
    width="83%"
    wrapClassName="add-power"
    @close="closeDetailDrawer"
    :destroyOnClose="true"
    :headerStyle="{
      background: '#fff',
      padding: '0.2rem 0.25rem 0 0.25rem',
      flexGrow: 0,
    }"
    :bodyStyle="{
      padding: '0.3rem 0 0.2rem 0',
      background: 'fff',
      flexGrow: 1,
      overflowY: 'auto',
    }"
    :drawerStyle="{
      background: '#EEEEEF',
      display: 'flex',
      flexDirection: 'column',
    }"
  >
    <template #title>
      <div class="head-box">
        <div class="name-box">
          <span>{{
            currentData?.robotTitle ? currentData.robotTitle : "--"
          }}</span>
          <div class="operation-right">
            <!-- <a-icon
                type="deployment-unit"
                title="查看所属系统"
                class="mr_16"
              />
              <a-icon type="control" title="设备控制" class="mr_16" />
              <a-icon type="to-top" title="固件升级" class="mr_16" /> -->
            <a-icon type="sync" title="刷新" class="mr_16" @click="getDetail" />
            <div class="close-box" @click="closeDetailDrawer">
              <a-icon type="close" class="close-icon" />
            </div>
          </div>
        </div>
      </div>
      <div class="drawer-tab-box">
        <div class="all-tabs">
          <span
            class="drawer-tab"
            @click="changeDrawerTab(drawertab.value)"
            v-for="drawertab in drawerTabList"
            :class="drawertab.value == drawerActive ? 'active-drawer-tab' : ''"
            >{{ drawertab.label }}</span
          >
        </div>
      </div>
    </template>
    <div class="content-box">
      <!-- <collect-detail></collect-detail> -->
      <div class="max-container">
        <template v-if="drawerActive == 1">
          <!-- 运行信息 -->
          <!-- <div class="row-box">
            <div class="content-box">
              <div class="operation-box">
                <span>操作：</span>
                <div class="operation-btn">
                  <div @click="robotStatu(1)">启动</div>
                  <div @click="robotStatu(0)">停止</div>
                  <div @click="resetRobot">复位</div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div class="row-box">
            <div class="content-box">
              <div class="title-box">
                <span>机器人运动轨迹</span>
                <div class="up-down" @click="changeFlag(1)">
                  {{ getInclude(1) ? "展开" : "收起"
                  }}<a-icon
                    type="up"
                    :class="getInclude(1) ? 'roteIcon' : ''"
                  />
                </div>
              </div>
              <div :class="getInclude(1) ? 'pack-up' : 'updown-box'">
                <div class="content-msg">
                  <div class="all-board">
                    <div class="grid-position">
                      <div v-for="i in 16" class="one-board"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="row-box">
            <div class="content-box">
              <div class="title-box">
                <span>运行信息</span>
                <div class="up-down" @click="changeFlag(2)">
                  {{ getInclude(2) ? "展开" : "收起"
                  }}<a-icon
                    type="up"
                    :class="getInclude(2) ? 'roteIcon' : ''"
                  />
                </div>
              </div>
              <div :class="getInclude(2) ? 'pack-up' : 'updown-box'">
                <div class="content-msg">
                  <div class="grid-box grid-base">
                    <div class="one-msg" v-for="item in baseInfo">
                      <span>{{ item.title }}</span>
                      <span class="one-detail"
                        >{{ item.value }}
                        {{ item.value == "--" ? "" : item.unit }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="row-box">
            <div class="content-box">
              <div class="title-box">
                <span>机器人参数</span>
              </div>
              <div class="params-box">
                <a-form-model ref="ruleForm" :model="form" :rules="rules">
                  <a-form-model-item
                    label="光伏板子数量（块）"
                    prop="boardNumber"
                  >
                    <a-input v-model="form.boardNumber" />
                  </a-form-model-item>
                  <a-form-model-item
                    label="光伏板子组数（组）"
                    prop="groupNumber"
                  >
                    <a-input v-model="form.groupNumber" />
                  </a-form-model-item>
                  <a-form-model-item label="一块板子宽度（cm）" prop="width">
                    <a-input v-model="form.width" />
                  </a-form-model-item>
                  <a-form-model-item label="一块板子高度（cm）" prop="height">
                    <a-input v-model="form.height" />
                  </a-form-model-item>
                </a-form-model>
                <div class="btn-box">
                  <a-button type="primary" @click="confirm">确认</a-button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </a-drawer>
</template>
<script>
import {
  robotDetail,
  robotStart,
  robotRun,
  resettingRobot,
  robotStop,
  robotParam,
  robotParamQuery,
} from "@/api/device";
import moment from "moment";
export default {
  data() {
    return {
      isOpen: false,
      moment,
      baseInfo: [
        {
          title: "环境温度：",
          value: "",
          prop: "temp",
          unit: "℃",
        },
        {
          title: "光电传感器计数值：",
          value: "",
          prop: "psCount",
          unit: "块",
        },
        {
          title: "接近开关状态：",
          value: "",
          prop: "switchStatus",
        },
        {
          title: "上电机运行电流：",
          value: "",
          prop: "upCurrent",
          unit: "A",
        },
        {
          title: "下电机运行电流：",
          value: "",
          prop: "downCurrent",
          unit: "A",
        },
        {
          title: "运行方向：",
          value: "",
          prop: "runDirector",
        },
        {
          title: "电池组电压：",
          value: "",
          prop: "batteryVoltage",
          unit: "V",
        },
        {
          title: "电池组剩余容量：",
          value: "",
          prop: "batteryPack",
        },
        {
          title: "电池组电流：",
          value: "",
          prop: "batteryCurrent",
          unit: "A",
        },
        {
          title: "电池组温度A：",
          value: "",
          prop: "batteryTempA",
          unit: "℃",
        },
        {
          title: "电池组温度B：",
          value: "",
          prop: "batteryTempB",
          unit: "℃",
        },
        {
          title: "电池组保护状态：",
          value: "",
          prop: "batteryProtectStatus",
        },
        {
          title: "光伏板输出电压：",
          value: "",
          prop: "pvOutVoltage",
          unit: "V",
        },
        {
          title: "光伏板输出电流：",
          value: "",
          prop: "pvOutCurrent",
          unit: "A",
        },
        {
          title: "状态改变标识：",
          value: "",
          prop: "changeStatusFlag",
        },
        {
          title: "系统告警：",
          value: "",
          prop: "systemAlarm",
        },
        {
          title: "设备运行状态：",
          value: "",
          prop: "deviceRunStatus",
        },
        {
          title: "机器人SN：",
          value: "",
          prop: "robotSn",
        },
        {
          title: "设备类型编码：",
          value: "",
          prop: "deviceTypeCode",
        },
        {
          title: "设备型号：",
          value: "",
          prop: "deviceModel",
        },
        {
          title: "上传固件版本数量：",
          value: "",
          prop: "upVersionNumber",
        },
        {
          title: "主控板版本：",
          value: "",
          prop: "boardVersion",
        },
        {
          title: "LoRa版本：",
          value: "",
          prop: "loraVersion",
        },
        {
          title: "BLE版本：",
          value: "",
          prop: "bleVersion",
        },
      ],
      intervalId: null,
      packUpFlag: [],
      currentData: null,
      drawerTabList: [
        {
          value: "1",
          label: "详情",
        },
        {
          value: "2",
          label: "设置机器人参数",
        },
      ],
      drawerActive: "1",
      form: {
        boardNumber: 0,
        groupNumber: 0,
        width: 0,
        height: 0,
      },
      rules: {
        boardNumber: [
          { required: true, message: "请填写光伏板子数量", trigger: "blur" },
        ],
        groupNumber: [
          { required: true, message: "请填写光伏板子组数", trigger: "blur" },
        ],
        width: [
          { required: true, message: "请填写一块板子宽度", trigger: "blur" },
        ],
        height: [
          { required: true, message: "请填写一块板子高度", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    getDetail(current) {
      if (current) {
        this.currentData = current;
      }
      console.log(current);
      robotDetail({ id: this.currentData.id }).then((res) => {
        this.baseInfo.forEach((item) => {
          item.value = res.data[item.prop];
        });
      });
      this.intervalId && clearInterval(this.intervalId);
      this.intervalId = setInterval(() => {
        robotDetail({ id: this.currentData.id }).then((res) => {
          this.baseInfo.forEach((item) => {
            item.value = res.data[item.prop];
          });
        });
      }, 2000);
    },
    robotStatu(flag) {
      if (flag) {
        robotRun({ id: this.currentData.id }).then((res) => {
          if (res.code == "000000") {
            this.$message.success(`启动成功`);
            // this.getTableList();
          } else {
            this.$message.error(`启动失败`);
          }
        });
      } else {
        robotStop({ id: this.currentData.id }).then((res) => {
          if (res.code == "000000") {
            this.$message.success(`关闭成功`);
            // this.getTableList();
          } else {
            this.$message.error(`关闭失败`);
          }
        });
      }
    },
    resetRobot() {
      resettingRobot({ id: this.currentData.id }).then((res) => {
        if (res.code == "000000") {
          this.$message.success(`复位成功`);
          // this.getTableList();
        } else {
          this.$message.error(`复位失败`);
        }
      });
    },
    getDetailInterval() {
      this.intervalId = setInterval(() => {
        this.getDetail();
      }, 2000);
    },
    getInclude(index) {
      return this.packUpFlag.includes(index);
    },
    changeFlag(index) {
      let findIndex = this.packUpFlag.findIndex((item) => {
        return item == index;
      });
      if (findIndex == -1) {
        this.packUpFlag.push(index);
      } else {
        this.packUpFlag.splice(findIndex, 1);
      }
    },
    closeDetailDrawer() {
      this.$emit("update:detailDrawer", false);
      this.drawerActive = "1";
      clearInterval(this.intervalId);
    },
    changeDrawerTab(index) {
      if (this.drawerActive == index) return;
      this.drawerActive = index;
      if (this.drawerActive == 2) {
        clearInterval(this.intervalId);
        this.getRobotParam();
      } else {
        this.getDetail();
      }
    },
    confirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          robotParam({
            ...this.form,
            robotId: this.currentData.id,
          }).then((res) => {
            if (res.code == "000000") {
              this.$message.success("设置机器人参数成功");
            }
          });
        }
      });
    },
    getRobotParam() {
      robotParamQuery({ robotId: this.currentData.id }).then((res) => {
        if (res.data) {
          for (let k in this.form) {
            this.form[k] = res.data[k] || 0;
          }
        } else {
          for (let k in this.form) {
            this.form[k] = 0;
          }
        }
        console.log(res);
      });
    },
  },
  props: {
    // currentData: {
    //   type: Object,
    //   default: () => {},
    // },
    detailDrawer: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // this.getDetail();
    // this.getDetailInterval();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>
<style lang="less" scoped>
.add-power {
  transform: translate(0) !important;
  .head-box {
    .name-box {
      display: flex;
      justify-content: space-between;
      span {
        font-size: 18px;
        color: #252b3a;
      }
      .operation-right {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.45);
        .mr_16 {
          margin-right: 16px;
          transition: all 0.5s;
          cursor: pointer;
          &:hover {
            color: #048fff;
          }
        }
        .close-box {
          background: #ff7875;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 28px;
          height: 28px;
          font-size: 16px;
          color: #fff;
          border-radius: 50%;
          &:hover {
            i {
              transform: rotate(90deg);
            }
          }
          i {
            transition: all 0.3s;
          }
        }
      }
    }
    .status-box {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      .left-box {
        display: flex;
        align-items: center;
        i {
          font-size: 12px;
        }
        span {
          font-size: 14px;
          color: #515561;
          margin-left: 6px;
        }
      }
      .right-time {
        font-size: 12px;
        color: #515561;
      }
    }
  }
  .drawer-tab-box {
    padding: 0 8px;
    margin-top: 16px;
    .all-tabs {
      display: flex;
      span {
        padding: 12px 0 8px;
        margin-right: 24px;
        font-size: 12px;
        color: #00000073;
        cursor: pointer;
      }
      .active-drawer-tab {
        color: #048fff;
        border-bottom: 2px solid #048fff;
        font-weight: 700;
      }
    }
  }

  .content-box {
    padding: 0 16px;
    box-sizing: border-box;
    .max-container {
      .row-box {
        position: relative;
        border: 1px solid rgba(37, 43, 58, 0.1);
        border-radius: 4px;
        background: #fff;
        padding: 16px 0;
        box-sizing: border-box;
        box-shadow: 0 2px 16px rgba(0, 0, 0, 0.05);
        margin-bottom: 8px;
        .content-box {
          padding: 0 16px;
          .params-box {
            width: 50%;
            .btn-box {
              display: flex;
              justify-content: flex-end;
            }
          }
          // 标题
          .title-box {
            display: flex;
            justify-content: space-between;
            span {
              font-size: 16px;
              color: #252b3a;
            }
            .up-down {
              font-size: 12px;
              color: #048fff;
              cursor: pointer;
              display: flex;
              align-items: center;
              i {
                font-size: 16px;
              }
              .roteIcon {
                transform: rotate(180deg);
              }
            }
          }
          .operation-box {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            span {
              font-size: 16px;
              color: #252b3a;
              margin-right: 20px;
            }
            .operation-btn {
              display: flex;
              div {
                margin-right: 24px;
                padding: 0 12px;
                font-size: 16px;
                cursor: pointer;
                color: #048fff;
              }
            }
          }
          .content-msg {
            .grid-box {
              display: grid;

              margin-top: 16px;
              .one-msg {
                font-size: 12px;
                color: #00000073;
                .one-detail {
                  color: #515561;
                }
              }
            }
            .grid-base {
              grid-template-rows: 1fr 1fr;
              grid-template-columns: 1fr 1fr 1fr;
              grid-row-gap: 20px;
            }
            .all-board {
              margin-top: 20px;
              padding-left: 40px;
              .grid-position {
                display: grid;
                grid-template-rows: 70px 70px;
                grid-template-columns: repeat(8, 40px);
                column-gap: 10px;
                row-gap: 5px;
                position: relative;
                width: max-content;
                .one-board {
                  background: #151b4b;
                }
              }
            }
          }

          .updown-box {
            overflow: hidden;
            max-height: 3000px;
          }
          .pack-up {
            overflow: hidden;
            height: 0;
          }
        }
      }
    }
  }
}
</style>